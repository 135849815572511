import Router from 'next/router';
import { LoadingPage } from '@/components/loadingPage/loadingPage';
import { Routes, addBasePath } from '@/utils/routes';
import useShowHome from '@/hooks/useShowHome';
import Home from '@/components/home/home';
import { useEffect } from 'react';
import useLaunchDarkly from '@/hooks/useLaunchDarkly';
import { ExperimentFlagsConstants } from '@/utils/experimentFlags';
import OnboardingModal from '@/components/onboardingModal/onboardingModal';

export default function HomePage() {
  const { loading: loadingShouldShowHome, shouldShowHome, canViewTransactions, canViewTransfers, canViewBilling } = useShowHome();
  const { variation } = useLaunchDarkly();

  useEffect(() => {
    if (variation) {
      variation(ExperimentFlagsConstants.aaTest);
      variation(ExperimentFlagsConstants.aaTestCto);
    }
  }, [variation]);

  useEffect(() => {
    if (!loadingShouldShowHome && !shouldShowHome) {
      if (canViewTransactions) {
        Router.replace(Routes.transactions, addBasePath(Routes.transactions));
      } else if (canViewTransfers) {
        Router.replace(Routes.transfers, addBasePath(Routes.transfers));
      } else if (canViewBilling) {
        Router.replace(Routes.billing, addBasePath(Routes.billing));
      } else {
        Router.replace(Routes.accountDetails, addBasePath(Routes.accountDetails));
      }
    }
  }, [loadingShouldShowHome, shouldShowHome, canViewTransactions, canViewTransfers, canViewBilling]);

  if (loadingShouldShowHome || !shouldShowHome) {
    return <LoadingPage />;
  }

  return (
    <>
      <OnboardingModal />
      <Home />
    </>
  );
}
