import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fhome%2FtotalTakings%2FtotalTakings.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA6VVUW%2BbMBB%2Bz6%2BwNE1qpVIBJZTSl7Xb%2Bge698rBF%2BIVzsgcS7Ip%2F32ygaYOZuu6FxCX83ff9935ckmKePWNP0ss26dCIXGJoB9pX8HT076r024Tsl8LxlZqF7Typ8QyZx22QLeLw%2BLSOb7iLXz2QkQWYgOy3FDO0qTZ3VpILUAHmgvZtTnLTPQUs5L47EDFFopgR4GAQmlOUmHOJG5ASzKwhaqUdiJCtk3F9zlbV2Arm3cgpIaiP63V1oS%2Fdy3J9T4wPgBSztqGFxCsgLYAaDIaLoS1IIqbHYsGIaOwmu%2FGsyZsPieaefFcatWhCAaeH%2B6Sh%2BTr8i3S8436AbpvxxQnTR%2FCL4l1oNOtCTVKIoGeQoPWSj8SJ3AKXFnov7pVqKqr0a9Glyt%2BFifRBesfV%2BkFi85NLq9kiYEkqNucFdATO3qXZb1Bg8MBqSZnUeqdCcVNypR%2FMmPNHKlTYNL7u5JLvO%2BIFDrQSwu9VkjmFsBIjbFKIrx0OR66vJWCNpN5sENrbTgaMKXAseXW6PZeib1DIrUknIojjdfMYp9pslDov5zXFnWgHGfuSI%2FfNdelxKCCNc1VIEmV249sYlriMy30oh2NuKtVh%2BQg35wi9yAz7bB52yG4DENT7VMNQnJ2VksMBu1pGDa7c4v8b4ttOsWHCcbMIuu3xEgg%2B%2Bg%2FPGet19yDX91N%2Bt%2FqrsN3qXuZ1WhwxxE9%2FJn0UTtk9urHY6qzEMaOHsMrRaRq3y%2FDrKancT2wmRXzx%2B14dOOVHIfjVThTcZI%2Bcu9QwFoiCJ%2BA2Yl6w6rwbqyZS%2FLOqYtfbDz8BmBjNrZNCAAA%22%7D"
export var baseContainerStyle = 'totalTakings_baseContainerStyle__yum6uh1';
export var containerStyle = 'totalTakings_containerStyle__yum6uh0';
export var errorStateStyle = 'totalTakings_errorStateStyle__yum6uh3 totalTakings_baseContainerStyle__yum6uh1';
export var iconContainerStyle = 'totalTakings_iconContainerStyle__yum6uh7';
export var linkStyle = 'totalTakings_linkStyle__yum6uh2 totalTakings_baseContainerStyle__yum6uh1';
export var loadingStateStyle = 'totalTakings_loadingStateStyle__yum6uh4 totalTakings_baseContainerStyle__yum6uh1';
export var titleStyle = 'totalTakings_titleStyle__yum6uh8';
export var transactionAmountStyle = 'totalTakings_transactionAmountStyle__yum6uh9';
export var transactionsBodyStyle = 'totalTakings_transactionsBodyStyle__yum6uh6 _1yasxssa _86u5x8d _86u5x82 _86u5x8p _86u5x88';
export var tryAgainButtonStyle = 'totalTakings_tryAgainButtonStyle__yum6uh5';