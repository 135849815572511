import { Permissions } from '@/utils/permissions';
import { Routes } from '@/utils/routes';
import useShowBusinessFunding from '@/hooks/useShowBusinessFunding';
import useShowNewQueueBooking from '@/hooks/useShowNewQueueBooking';
import useShowQueueBooking from '@/hooks/useShowQueueBooking';
import {
  AddTeamMembers,
  Billing,
  BusinessFunding,
  Insights,
  OnlineCheckout,
  OrderCardMachines,
  Payments,
  QueuesAndBookings,
  Refunds,
  Transactions,
  Transfers,
  ViewCardMachines,
  ViewTeamMembers,
  DeveloperPortal,
  QrCode,
  Reverse,
  Help,
  Card,
} from '@dojo-engineering/react-icons';
import { AnalyticsHomeVariantConstants } from '@/constants/analytics/home-variant';
import { ReactElement } from 'react';
import TiledSection from './tiledSection';
import useShowPayByQr from '@/hooks/useShowPayByQr';
import useShowNewOnlinePayments from '@/hooks/useShowNewOnlinePayments';
import useShouldShowDojoCardInterest from '@/hooks/useShouldShowDojoCardInterest';

export default function BottomSections({ shouldShowOnlinePayments }: { shouldShowOnlinePayments: boolean }): ReactElement | null {
  const shouldShowBusinessFunding = useShowBusinessFunding();
  const shouldShowOldQueuesBooking = useShowQueueBooking();
  const shouldShowNewQueuesBooking = useShowNewQueueBooking();
  const shouldShowPayByQr = useShowPayByQr();
  const shouldShowNewOnlinePayments = useShowNewOnlinePayments();
  const { shouldShowDojoCardInterest } = useShouldShowDojoCardInterest();

  return (
    <>
      <TiledSection
        heading="home:home.list.manage_payments"
        requiredPermissions={[Permissions.transactionsAllView]}
        tiles={[
          {
            heading: 'home:home.list.payments',
            body: 'home:home.list.payments_description',
            Icon: <Payments />,
            linkPath: Routes.payments,
            permissions: [Permissions.paymentLinksView],
            shouldShow: shouldShowOnlinePayments,
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.payments,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.payments,
          },
          {
            heading: 'home:home.list.refunds',
            body: 'home:home.list.refunds_description',
            Icon: <Refunds />,
            linkPath: Routes.transactions,
            permissions: [Permissions.transactionsAllRefund, Permissions.transactionsF2fRefund],
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.refunds,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.refunds,
          },
          {
            heading: 'home:home.list.reverse',
            body: 'home:home.list.reverse_description',
            Icon: <Reverse />,
            linkPath: Routes.transactions,
            permissions: [Permissions.transactionsAllReverse],
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.reverse,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.reverse,
          },
          {
            heading: 'home:home.list.pay_by_qR',
            body: 'home:home.list.pay_by_qR_description',
            Icon: <QrCode />,
            linkPath: Routes.payByQr,
            shouldShow: shouldShowPayByQr,
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.payByQR,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.PayByQr,
          },
        ]}
      />
      <TiledSection
        heading="home:home.list.manage_your_cashflow"
        tiles={[
          {
            heading: 'home:home.list.transactions',
            body: 'home:home.list.transactions_description',
            Icon: <Transactions />,
            linkPath: Routes.transactions,
            permissions: [Permissions.transactionsAllView],
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.transactions,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.transactions,
          },
          {
            heading: 'home:home.list.transfers',
            body: 'home:home.list.transfers_description',
            Icon: <Transfers />,
            linkPath: Routes.transfers,
            permissions: [Permissions.transfersAllView],
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.transfers,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.transfers,
          },
          {
            heading: 'home:home.list.billing',
            body: 'home:home.list.billing_description',
            Icon: <Billing />,
            linkPath: Routes.billing,
            permissions: [Permissions.billingInvoiceView],
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.billing,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.billing,
          },
        ]}
      />
      <TiledSection
        heading="home:home.list.grow_your_business"
        tiles={[
          {
            heading: 'home:home.list.business_funding',
            body: 'home:home.list.business_funding_description',
            Icon: <BusinessFunding />,
            linkPath: Routes.businessFunding,
            permissions: [Permissions.businessFundingView],
            shouldShow: shouldShowBusinessFunding,
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.businessFunding,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.businessFunding,
          },
          {
            heading: 'home:home.list.insights',
            body: 'home:home.list.insights_description',
            Icon: <Insights />,
            linkPath: Routes.insights,
            permissions: [Permissions.businessInsightsView],
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.insights,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.insights,
          },
          {
            heading: 'home:home.list.dojo_card',
            body: 'home:home.list.dojo_card_interest_description',
            Icon: <Card />,
            linkPath: Routes.dojoCard,
            permissions: [],
            shouldShow: shouldShowDojoCardInterest,
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.dojoCard,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.dojoCard,
          },
        ]}
      />
      <TiledSection
        heading="home:home.list.manage_your_card_machines"
        requiredPermissions={[Permissions.cardMachinesDetailsView]}
        tiles={[
          {
            heading: 'home:home.list.view_card_machines',
            body: 'home:home.list.view_card_machines_description',
            Icon: <ViewCardMachines />,
            linkPath: Routes.cardMachines,
            permissions: [Permissions.cardMachinesDetailsView],
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.viewCardMachines,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.viewCardMachines,
          },
          {
            heading: 'home:home.list.card_machine_orders',
            body: 'home:home.list.card_machine_orders_description',
            Icon: <ViewCardMachines />,
            linkPath: Routes.cardMachinesTerminalOrders,
            permissions: [Permissions.cardMachinesOrderCreate],
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.cardMachineOrders,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.cardMachineOrders,
          },
          {
            heading: 'home:home.list.order_card_machines',
            body: 'home:home.list.order_card_machines_description',
            Icon: <OrderCardMachines />,
            linkPath: Routes.cardMachinesTerminalOrderReason,
            permissions: [Permissions.cardMachinesDetailsView],
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.orderCardMachines,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.orderCardMachines,
          },
        ]}
      />
      <TiledSection
        heading="home:home.list.take_payments_online"
        tiles={[
          {
            heading: 'home:home.list.view_online_checkout',
            body: 'home:home.list.view_online_checkout_description',
            Icon: <OnlineCheckout />,
            linkPath:
              shouldShowOnlinePayments && shouldShowNewOnlinePayments ? Routes.newOnlinePayments : shouldShowOnlinePayments ? Routes.onlinePayments : null,
            shouldShow: shouldShowOnlinePayments,
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.onlineCheckout,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.onlineCheckout,
          },
          {
            heading: 'home:home.list.view_developer_portal',
            body: 'home:home.list.view_developer_portal_description',
            Icon: <DeveloperPortal />,
            linkPath: Routes.onlinePayments,
            permissions: [Permissions.developerPortalView],
            shouldShow: shouldShowOnlinePayments,
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.developerPortal,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.developerPortal,
          },
        ]}
      />
      <TiledSection
        heading="home:home.list.manage_your_permissions"
        requiredPermissions={[Permissions.teamMembersView]}
        tiles={[
          {
            heading: 'home:home.list.view_team_members',
            body: 'home:home.list.view_team_members_description',
            Icon: <ViewTeamMembers />,
            linkPath: Routes.teams,
            permissions: [Permissions.teamMembersView],
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.viewTeamMembers,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.viewTeamMembers,
          },
          {
            heading: 'home:home.list.add_team_members',
            body: 'home:home.list.add_team_members_description',
            Icon: <AddTeamMembers />,
            linkPath: Routes.teams,
            permissions: [Permissions.teamMembersManage],
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.addTeamMembers,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.addTeamMembers,
          },
        ]}
      />
      <TiledSection
        heading="home:home.list.virtual_qabs"
        tiles={[
          {
            heading: 'home:home.list.manage_queues_and_bookings',
            body: 'home:home.list.manage_queues_and_bookings_description',
            Icon: <QueuesAndBookings />,
            linkPath: shouldShowNewQueuesBooking ? Routes.newQueuesBooking : Routes.queuesBooking,
            shouldShow: shouldShowNewQueuesBooking || shouldShowOldQueuesBooking,
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.virtualQueuesAndBookings,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.virtualQueuesAndBookings,
          },
        ]}
      />
      <TiledSection
        heading="home:home.list.help_and_support"
        tiles={[
          {
            heading: 'home:home.list.visit_help_centre',
            body: 'home:home.list.visit_help_centre_description',
            Icon: <Help />,
            linkPath: {
              url: Routes.help,
              external: true,
              target: '_blank',
            },
            analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.visitHelpCentre,
            analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.visitHelpCentre,
          },
        ]}
      />
    </>
  );
}
