import { activeIndicatorDot, centeringContainer, indicatorDot } from './stepIndicators.css';

type StepIndicatorsProps = {
  stepCount: number;
  currentStep: number;
  onIndicatorClick: (index: number) => void;
};

const StepIndicators = ({ stepCount, currentStep, onIndicatorClick }: StepIndicatorsProps) => (
  <div className={centeringContainer}>
    {Array.from({ length: stepCount }).map((_, index) => (
      <div onClick={() => onIndicatorClick(index)} key={index} className={index == currentStep ? activeIndicatorDot : indicatorDot} />
    ))}
  </div>
);

export default StepIndicators;
