export enum LOCALES {
  enGB = 'en-GB',
  enIE = 'en-IE',
  frFr = 'fr-FR',
  esEs = 'es-ES',
  itIt = 'it-IT',
}

export enum CURRENCIES {
  GBP = 'GBP',
  EUR = 'EUR',
}

export const MIN_AMOUNT = 0.01;
export const MAX_AMOUNT = 1000000;

export enum AddressConfiguration {
  BillingAddressOnly = 'Billing address only',
  BillingAndShippingAddress = 'Billing and shipping address',
}

export enum PaymentSteps {
  Setup = 'Setup',

  // payment links
  CreatePaymentLinks = 'CreatePaymentLinks',
  SharePaymentLinks = 'SharePaymentLinks',
  QRCodePaymentLinks = 'QRCodePaymentLinks',

  // virtual terminal
  CreateVirtualTerminal = 'CreateVirtualTerminal',
  CheckoutVirtualTerminal = 'CheckoutVirtualTerminal',

  // pay by bank
  CreatePayByBank = 'CreatePayByBank',
  SharePayByBank = 'SharePayByBank',
  QRCodePayByBank = 'QRCodePayByBank',
}

export enum PaymentMedium {
  QRCode = '?medium=qrcode',
  Link = '?medium=link',
}

export const TAKE_PAYMENT_QUERY_KEY = 'takePayment';
export enum TakePaymentQueryValues {
  PaymentLink = 'payment-link',
  VirtualTerminal = 'virtual-terminal',
  PayByBank = 'pay-by-bank',
}

export const OPEN_CREATE_PAYMENT_MODAL_EVENT = 'openCreatePaymentModal';

export const SELECTED_PAYMENT_LOCATION_ID_LOCAL_STORAGE = 'SELECTED_PAYMENT_LOCATION_ID';

export const CURRENCY = 'currency';
