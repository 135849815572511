/**
 * Format numbers into currency strings
 *
 * @param number       value: value to be converted
 * @param boolean      valueInSubunits: convert subunits to base units (divide by 100)
 * @param string       currencyCode: currency code GBP, EUR
 * @param string       locale: currency locale (if undefined use browser default)
 * @param number       maximumFractionDigits: maximum number of fraction digits to use
 * @param number       minimumFractionDigits: minimum number of fraction digits to use
 */

export default function currencyFormat(
  value: number,
  valueInSubunits: boolean = true,
  currencyCode: string = 'GBP',
  locale: string = undefined,
  maximumFractionDigits: number = 2,
  minimumFractionDigits: number = maximumFractionDigits
) {
  // minimumFractionDigits is defaulted to 2 for currency formatting
  // needs to be equal or less than maximumFractionDigits
  // is required if maximumFractionDigits is specified
  // otherwise the tests will fail
  const userLocale = locale || navigator.language;

  const numberFormat = new Intl.NumberFormat(userLocale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  });
  const valueToBeConverted = valueInSubunits ? value / 100 : value;
  return numberFormat.format(valueToBeConverted);
}
