import React, { memo } from 'react';
import { usePermissions } from '@/providers/permissions';

type SectionAuthorizationProps = {
  children: React.ReactNode;
  permissionShouldShows: { permission: string; shouldShow: boolean }[];
  requiredPermissions?: string[];
};

const SectionAuthorization = ({ children, permissionShouldShows, requiredPermissions }: SectionAuthorizationProps) => {
  const { permissions } = usePermissions();

  if (requiredPermissions && requiredPermissions.length > 0 && requiredPermissions.some((rP) => !permissions?.some((p) => p.name === rP && p.enabled))) {
    return null;
  }

  if (permissionShouldShows.some((pSS) => pSS.shouldShow && (!pSS.permission || permissions?.some((p) => p.name === pSS.permission && p.enabled)))) {
    return <>{children}</>;
  }

  return null;
};

export default memo(SectionAuthorization);
