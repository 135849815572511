import useAnalytics from '@/hooks/useAnalytics';
import { i18n } from '@paymentsense/dj-i18n';
import Link from 'next/link';
import { Routes, addBasePath } from '@/utils/routes';
import { buttonRecipe, headingRecipe, bodyRecipe, clsx, sprinkles } from '@dojo-engineering/styled-system';
import { DateRange, useHomepageTotalTakingsQuery } from '@/graphql/generated';
import {
  tryAgainButtonStyle,
  errorStateStyle,
  loadingStateStyle,
  transactionsBodyStyle,
  iconContainerStyle,
  titleStyle,
  transactionAmountStyle,
  linkStyle,
  containerStyle,
} from './totalTakings.css';
import { ChevronRight } from '@dojo-engineering/react-icons';
import { AnalyticsHomeVariantConstants } from '@/constants/analytics/home-variant';
import { AnalyticsCategory } from '@/constants/analytics';
import currencyFormat from '@/utils/currencyFormat';
import { ReactElement } from 'react';

interface TotalTakingsProps {
  customerId: string;
  locationIds: string[];
}

export default function TotalTakings({ customerId, locationIds }: TotalTakingsProps): ReactElement | null {
  const { analytics } = useAnalytics();
  const searchTransactionQueryFilters = {
    customerId: customerId,
    locationIds: locationIds,
    dateRange: DateRange.Today,
    timeRange: 'bankingWindow',
    transactionTypes: [],
    outcomes: [],
    schemes: [],
    sourceTypes: [],
    cardTypes: [],
    cardMachineTerminalIds: [],
    toTime: '00:00',
    fromTime: '00:00',
  };

  const { data, loading, error, refetch } = useHomepageTotalTakingsQuery({
    variables: {
      search: {
        filters: searchTransactionQueryFilters,
        pageSize: 5,
      },
    },
    fetchPolicy: 'network-only',
    skip: !customerId || !locationIds,
  });

  const netSales = data?.searchTransactions.searchTotals.netSales;
  const currencyCode = data?.searchTransactions.searchTotals.currencyCode;
  const transactionCount = data?.searchTransactions?.searchTotals.count;
  const valueForDisplay = netSales ?? 0;
  const safeTransactionCount = !!transactionCount ? transactionCount : 0;

  const handleClick = () => {
    analytics.clickedElementV2(
      AnalyticsCategory.homepage,
      AnalyticsHomeVariantConstants.actions.selected + AnalyticsHomeVariantConstants.actionSuffixes.totalTakings,
      null,
      { pageSection: AnalyticsHomeVariantConstants.pageSection.totalTakings }
    );
  };

  const refetchQuery = () => {
    refetch();
  };

  if (loading) {
    return <div className={loadingStateStyle} />;
  }

  if (error) {
    return (
      <div className={errorStateStyle}>
        <span className={clsx(bodyRecipe({ variant: 'default', size: 'small', color: 'secondary' }))}>
          {i18n.t('home:home.overview.transactions_error_message')}
        </span>
        <a
          className={clsx(
            buttonRecipe({
              kind: 'text',
              size: 'regular',
              minWidth: true,
            }),
            tryAgainButtonStyle
          )}
          onClick={refetchQuery}
        >
          {i18n.t('error:error.try_again.action')}
        </a>
      </div>
    );
  }

  return (
    <div className={containerStyle}>
      <Link className={linkStyle} href={Routes.transactions} as={addBasePath(Routes.transactions)} prefetch={false} onClick={handleClick}>
        <div className={sprinkles({ display: 'flex', flexDirection: 'column', justifyContent: 'center' })}>
          <span className={clsx(headingRecipe({ h: 5 }), titleStyle)} style={{ marginBottom: '4px' }}>
            {i18n.t('home:home.list.todays_total_takings')}
          </span>
          <span className={transactionsBodyStyle}>
            {safeTransactionCount > 0
              ? `${i18n.t('home:home.list.transaction_total', { count: safeTransactionCount })}`
              : i18n.t('home:home.list.no_transactions')}
          </span>
        </div>
        <div className={sprinkles({ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' })}>
          <span className={transactionAmountStyle}>{currencyFormat(valueForDisplay, true, currencyCode)}</span>
          <ChevronRight className={iconContainerStyle} />
        </div>
      </Link>
    </div>
  );
}
