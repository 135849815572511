import { RefObject, useEffect, useRef } from 'react';

export default function useElementEnteredViewport(elementRef: RefObject<Element>, callback: () => void) {
  const enteredPreviously = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (enteredPreviously.current === false && entry.isIntersecting) {
        enteredPreviously.current = true;
        callback();
        observer.unobserve(entry.target);
      }
    });

    observer.observe(elementRef.current);

    return () => observer.disconnect();
  }, [callback, elementRef]);
}
